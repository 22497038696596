/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import { Layout, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = 'Introducing The New Version Of Njano !';
  const description =
    'Meet our all-in-one management solution enhanced to further help coworkers and incubators run their operations frustration-free.';
  return <SEO title={title} description={description} location={location} />;
};

const ArticlePage = () => (
  <Layout dark logo>
    <div className="md:max-w-5xl container w-full px-16 pt-20 mx-auto bg-white shadow-lg">
      <div className="md:px-6 w-full px-4 text-xl leading-normal text-gray-800">
        <div className="font-content">
          <span className="text-primary md:text-sm space-y-8 text-base font-bold">
            <Link to="/" className="bg-secondary md:text-sm px-6 py-2 text-base font-bold text-white no-underline">
              &#8592; BACK TO HOME
            </Link>
            <h1 className="font-heading md:text-5xl pt-6 pb-2 text-3xl font-bold text-gray-900 break-normal">
              Introducing The New Version Of Njano !
            </h1>
          </span>
        </div>
        <p className="py-6">
          We are excited to announce that the new version of Njano is now online and available to everyone!
        </p>

        <p className="py-6">
          Meet our all-in-one management solution enhanced to further help coworkers and incubators run their operations
          frustration-free.
        </p>
        <p className="py-6">
          Discover some highlights of our new releases:
          <ul>
            <li>
              <b> ⚡️ Variety of logging options:</b> henceforth, Njano enables you to sign and log in using your
              Google, Facebook and Linkedin accounts
            </li>
            <li>
              <b> ⚡️ A more inclusive experience:</b> by few clicks, you can easily switch between incubator and
              coworking features. Now you can run both at the same time!
            </li>
            <li>
              <b> ⚡️ An exciting embedded learning journey:</b> We checked a new milestone in Njano.. A new E-learning
              section has joined the game!
            </li>
            <li>
              Enjoy creating a fruitful knowledge experience to your community by adding a wide range of resources
              (videos, articles, blogs, ..)
            </li>
            <li>
              <b> ⚡️ More data-driven management:</b> your data is now a few clicks away. Njano solution enables you to
              export events attendance, your community database as well as their responses to campaigns.
            </li>
            <li>
              <b> ⚡️ Keep up with any updates:</b> we worked on upgrading our notifications system on Njano. From now
              on, you can allow Njano to send you browser notifications along with an embedded notifications bell that
              gives you and your community real-time updates and reminders.
            </li>
          </ul>
        </p>
        <p className="py-6">
          We are excited to have you discover these new releases. Don’t think twice and jump right to your Njano access
          to know more about this new experience. And as always, feel free to reach out to our team for any assistance
          or feedback 💛
        </p>
      </div>
    </div>
  </Layout>
);

export default ArticlePage;
